

import {Link } from "react-router-dom";




export default function Foot ()
{

    return(
            <>
            
            
            <footer>
                    <div className='footerContainer'>
                        <div className='footerContainerSub'>

                            <div className='footerContainerSubCard'>
                                    <h3>Sekai Resources Ltd</h3>
                                    <p>
                                    
                                    Sekai Resources Ltd is a gold miner of scale, with a diversified, high quality global asset portfolio and a proven track record of new mine development, having completed three new large-scale mines since 2013, each with a payback period of under 30 months. 




                                    </p>
                            </div>


                            <div className='footerContainerSubCard'>
                                <h4>LINKS</h4>
                                <ul>
                                    <li><Link to='/'>Home </Link></li>
                                    <li><Link to='/about'>About  </Link></li>
                                    <li><Link to='/faq'> Faq </Link></li>
                                    <li><Link to='/contact'> Contact </Link> </li>
                                </ul>
                            </div>
                            <div className='footerContainerSubCard'>
                                <h3>CONTACT</h3>
                                    <p>
                                        Office: Suite #1100 – 1133 Melvillen St, Vancouver, BC, V7E 4E5 Canada. <br />
                                        Regional office : South, Randfontein, 1760, South Africa. <br />



                                    </p>

                                    {/* <p>
                                        Email: <br />
                                        support@sekairesourceslimited.com
                                    </p> */}

                            </div>

                            <p className="clearx"></p>

                            <p className='footLastText'>
                                © 2024 Sekai Resources Ltd. All Rights Reserved.

                            </p>
                        </div>

                    </div>
                </footer>









            
            
            
            
            
            
            </>

    );




}