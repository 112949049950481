
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";

export default function About ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSection'>

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img1'> </div>
                        </div>
                        <div className='aboutSection_sub2_2'>

                        <h2>About Sekai Resources Ltd </h2>
            
                               


                        <p style={{ color: "#333",fontWeight: "bold", textTransform: "uppercase" }}>
                                Sekai Resources Ltd is a Canadian mining exploration and development company focused on revitalizing the Eskay Creek and Snip Projects, two past-producing mines located in Tahltan Territory in the Golden Triangle of northwest British Columbia, Canada.

                                </p>

                                <p style={{ color: "#333",fontWeight: "bold",textTransform: "uppercase"  }}>
                                Sekai Resources Ltd is an internationally diversified gold producer with over one million ounces of production per annum, operating a portfolio of eight mines located in the Russian Federation, Kazakhstan, Burkina Faso and South Africa

                                </p>

                                <p style={{ color: "#333", }}>
                                We take pride in everything we do. That’s why we aim to exceed your expectations and return outstanding value through our wide range of products and services.


                                </p>

                                <p style={{ color: "#333", }}>
                                A better world is important to us too. That’s why we work closely with not-for-profits and community organisations and support their initiatives through fundraising, donations and volunteering.


                                </p>

                                <h5> <b>  Our Mission: </b> </h5>
                                <p style={{ color: "#333", }}>
                                To become Canada's premier mineral development team; admired for our innovation, our creativity, and our commitment to deliver value and prosperity wherever we operate.

                                </p>



                                <h5> <b>Our Vision: </b> </h5>

                                <p style={{ color: "#333", }}>
                                To build an industry leading mining company that delivers value and prosperity to shareholders, employees, Indigenous Nation partners, and surrounding communities, and is committed to reconciliation with Indigenous peoples through responsible and sustainable mining development.

                                </p>
                                <h5><b>Values:</b></h5>
                                <p style={{ color: "#333", }}>
                                1. Health & Safety – everyone safe, every day. 
                                <br />
                                2. Environment – respect and protect the land for future generations.
                                <br />
                                3. Community – hand in hand, move forward together.
                                <br />
                                4. Integrity – be open, honest, and transparent.
                                <br />
                                5. Accountability – focus on commitments and deliverables.
                                <br />
                                6. Innovation – find creative solutions by leveraging new and emerging technologies.
                                {/* <br />
                                7. Execution – deliver consistently, efficiently and beyond what is expected. */}
                                
                                </p>


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>




                    <section>

                        <div  className='aboutSection1'>
                            <div className='aboutSection1Sub'>
                                <div className='aboutSection1SubCard'>
                                   
                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg1'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>24/7 Support</h2>
                                            <p>
                                            We provide 24/7 customer support through e-mail and live chat.
                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                        
                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg11'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>Instant Withdrawal</h2>
                                            <p>
                                            
                                            All withdrawal requests are treated spontaneously once requested.

                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                                <div className='aboutSection1SubCard'>

                                    <div className='aboutSection1SubCardImg'>
                                        <div className='aboutSection1SubCardImg111'></div>

                                        <div className='aboutSection1SubCardImg2'>
                                            <h2>Fund Security</h2>
                                            <p>
                                            
                                            Invest with peace of mind, as we globally regulated and licensed bank.
                                            </p>
                                        </div>

                                        <p className="clearx"></p>
                                    </div>

                                </div>
                            </div>

                        </div>



                    </section>




                    <section>
                <div className='whyChooseUsSection'>
                    <div className='whyChooseUsSectionContainer'>

                            <div className='whyChooseUsSectionContainerHeading'>
                                <h2>Reasons Why We Are The Best.</h2>
                                <p>
                                
                                Established in 2007, Sekai Resources Ltd has since grown to become a leading, one million ounce gold producer by pursuing a disciplined strategy of well thought-through investment and continuous operational improvement.
                                </p>
                            </div>


                            <div className='whyChooseUsSectionContainerDetails'>

                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2> ENVIRONMENTAL </h2>
                                        <p>
                                        Sekai Resources Ltd is committed to responsibly managing the natural resources where we operate, through our environmental management system that addresses water, waste, and biodiversity impacts. The natural advantages offered by Eskay Creek, a high-grade project with access to low-carbon hydropower, means that its greenhouse gas emissions are projected to be in the lowest amongst global gold producers. We continue to investigate opportunities to reduce our emissions through further electrification of our operations.


                                        </p>
                                    </div>
                                    
                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>
                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>GOVERNANCE</h2>
                                        <p>
                                        Sekai Resources Ltd believes that business has a crucial role to play in sustainable development. As a participant in the United Nations Global Compact (UNGC), we incorporate the UNGC Ten Principles covering human rights, labour, environment, and anti-corruption as an ethical and practical framework for operationalizing corporate sustainability. Our Board of Directors is accountable for Sekai Resources Ltd’s environmental and social performance. Sekai Resources Ltd’s 100% independent Nomination & Governance Committee has oversight of corporate governance policies, including our Code of Ethics and Diversity, Whistleblower and Anti-Bribery & Anti-Corruption Policies.


                                       
                                        </p>
                                    </div>
                                    

                                </div>
                                <div className='whyChooseUsSectionContainerDetailsCard'>

                                    <div className='whyChooseUsSectionContainerDetailsCard_Details'>
                                        <h2>PARTNERSHIPS</h2>
                                        <p>
                                        Sekai Resources Ltd looks to advance and support an environmentally and socially responsible mineral sector through partnerships with First Nations, governmental organizations, suppliers, academia, and non-governmental organizations (NGOs). We are proud to support the following partnerships and initiatives: BC Regional Mining Alliance, Mining Association of British Columbia, Taskforce on Climate-Related Financial Disclosures, and United Nations Global Compact.

                                        
                                        </p>
                                    </div>
                                </div>



                       

                                <p className="clearx"></p>
                            </div>



                    </div>


                </div>

        </section>

        {/* our team */}


        <section>
            <div className='ourTeamContainer'>

                <div className='ourTeamContainerSub'>
                    <h2>Our Teams</h2>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                    <img src="team/t1.png" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Phil Sutliff
                                    <br/>
                                    <span style={{ fontSize:10, color: "#333", }}> Executive Chairman </span>
                                </p>
                                
                            </center>

                        </div>

                        <div className='ourTeamContainerSubCard'>

                            <center>
                                <div className='founderImg'>
                                <img src="team/t2.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Jennifer Rodriguez

                                    <br/>
                                    <span style={{ fontSize:10, color: "#333", }}> Director
 </span>
                                </p>
                            </center>

                        </div>
                        <div className='ourTeamContainerSubCard'>
                            
                            <center>
                                <div className='founderImg'>
                                <img src="team/t3.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Kevin Martin


<br/>
<span style={{ fontSize:10, color: "#333", }}> Senior Account Manager

</span>
</p>
                            </center>


                        </div>
                        <div className='ourTeamContainerSubCard'>

                            
                            <center>
                                <div className='founderImg'>
                                <img src="team/4.jpeg" alt="ceo" width={80} height={80} style={{ borderRadius:"50%", }} />
                                </div>
                                <p>Susan Gluszynski



<br/>
<span style={{ fontSize:10, color: "#333", }}> Director


</span>
</p>
                            </center>


                        </div>



                    <p className="clearx"></p>
                </div>


            </div>


        </section>




{/* faq section */}
<section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p style={{ color: "#333", }}>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is opening an account is free?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div className="accordion-body">
                                        
                                        Yes, we don&#039;t take any fees for opening an account.

                                       </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        What is Sekai Resources Ltd?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333", textTransform: "lowercase,"}}>
                                        <div class="accordion-body">
                                        
                                        SEKAI RESOURCES LTD IS AN INTERNATIONALLY DIVERSIFIED GOLD PRODUCER WITH OVER ONE MILLION OUNCES OF PRODUCTION PER ANNUM, OPERATING A PORTFOLIO OF EIGHT MINES LOCATED IN THE RUSSIAN FEDERATION, KAZAKHSTAN, BURKINA FASO AND GUINEA


                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                            How to open an account?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Get the registration form by clicking on the Sign Up button on the top bar. Provide all information and click on the Sign Up button.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How do i reset my password?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If you have lost your pasword or you want to change it to a new one. Please click on the forgot password option and follow the steps to retrieve your password.
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How to take a loan?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#092980"}}>
                                        <div class="accordion-body">
                                        We have several loan plans. Choose the best plan suitable for you and just click on the Apply Now button and put the amount.

                                        </div>
                                        </div>
                                    </div>

                                    

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is Sekai Resources Ltd secure?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes. 100% Security. We measure up to all the reliable security operations.
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>
            



            <div className='dfffcc'></div>
                









                <Foot />
            
            
            
            
             </>

    );


}