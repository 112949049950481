



export default function DashFoot(){
    return (
        <>
        
        <div className="mainDashContent_Footer">
                                         <p className="mainDashContent_Footer_para">2024 Copyright Reserved. Sekai Resources Ltd</p>
        </div> 
        
        
        </>
    );
}