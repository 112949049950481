// AccountSetUp2









import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";

import {useState} from 'react';
import axios from 'axios';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

// import dash images
import dashimg1 from "../DashImg/dashimg1.svg";

import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";


export default function AccountSetUp2(){

    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");


    const [maritalStatus, setmaritalStatus] = useState("");
    const [gender, setgender] = useState("");
    const [occupation, setoccupation] = useState("");
    const [dob, setdob] = useState("");
    



    const goToPage = (e) => {
        e.preventDefault();


        if(maritalStatus == "" || maritalStatus == "Select"){

            toast.error("Select your marital status");
            setErrMsg("Select your marital status");
            return false;

        }else if(gender == "" || gender == "Select"){

            toast.error("Select your gender");
            setErrMsg("Select your gender");
            return false;

        }else if(occupation == ""){

            toast.error("Enter your occupation");
            setErrMsg("Enter your occupation");
            return false;

        }else if(dob == ""){

            toast.error("Select your date of birth");
            setErrMsg("Select your date of birth");
            return false;




        }else{


            Cookies.set('maritalStatus', maritalStatus, { expires: 1 });
            Cookies.set('gender', gender, { expires: 1 });
            Cookies.set('occupation', occupation, { expires: 1 });
            Cookies.set('dob', dob, { expires: 1 });
            
            navigateRoutes('/accountSetUp3');


        }








       
    }


    





    return (
        <>
            <div className="mainDashIndexContainer" >

                {/* import dashnav here */}

                   
                   


             {/* main section */}
             <section className="mainContainerSection" >


                {/* import dash nav here */}
                   






                

            {/* main */}

                <main className="mainDashContent" >



                    {/* start container holding main content */}
                   <div className="mainDashContent_Sub" >

                        
                        {/* start other content enters here */}
                        <div className="mainDashContent_Sub_Content" >

                                        {/* dash view content */}
                                <div className="mainContent_1" >

                                    <div className="mainContent_1_Container" >
                                        


                                    <ToastContainer /> 





                                        {/* box 1 */}
                                        {/* <div className="mainContent_1_Container_box_1" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "40%",
                                         }}>
                                            <div className="mainContent_1_Container_box_1_Content">
                                               
                                                <h2>   Welcome To Sekai Resources Ltd </h2>
                                                <small style={{ color: "#fff",  }}>
                                                    Hold while we redirect you to your banking dashboard.
                                                </small>

                                                    <br />
                                                    <br />
                                                <div class="spinner-border text-light" role="status">
                                                    <span class="sr-only">  </span>
                                                </div>

                                            </div>

                                        </div> */}

                                        <div className="depositMainContainer" style={{ 
                                            margin:0,
                                            position: "absolute",
                                            top: "0%",
                                            
                                         }}>
                                           
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                

                                                {/* progress bar1 */}
                                            <div className="progressContainerForCode">
                                                <div className="progressBar_34" style={{ 
                                                    width: "60%",
                                                    height: 7,
                                                    backgroundColor: "green",
                                                 }}></div>
                                            </div>
                                           
                                            <form>
                                               
                                                    <h3 className="codeLabel"> Account Setup (2/3)  </h3>
                                                    <br />

                                                    <p style={{ color: "red" }}>
                                                        {errMsg}
                                                    </p>


                                                 <label htmlFor="fullname" className='depositFormLabel'>Marrital Status  </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                    <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setmaritalStatus(e.target.value)}>
                                                        <option value="select">-- Select --</option>

                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="Widowed">Widowed</option>
                                                        <option value="Divorced">Divorced</option> 
                                                </select>
                                                    </div>
                                                    <br />

                                                    <label htmlFor="fullname" className='depositFormLabel'>Select Gender  </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                    <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setgender(e.target.value)}>
                                                    <option value="select">-- Select --</option>

                                                            <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Others">Others</option>
                                                </select>
                                                    </div>
                                                    <br />
                                                    
                                                 <label htmlFor="fullname" className='depositFormLabel'>Enter Occupation </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                        <div className="depositAmountContainer_2">
                                                        <input type="text" placeholder="Enter Occupation" onChange={(e) => setoccupation(e.target.value)}   className='DepositformInputAmount' />
                                                        
                                                        </div>
                                                    </div>
                                                    <br />
                                                    
                                                 <label htmlFor="fullname" className='depositFormLabel'>Select Date Of Birth </label> <br />   
                                                    <div className="depositAmountContainer">
                                                        
                                                        <div className="depositAmountContainer_2">
                                                        <input type="date" placeholder="Enter City"  onChange={(e) => setdob(e.target.value)}  className='DepositformInputAmount' />
                                                        
                                                        </div>
                                                    </div>
                                                    {/* <br /> */}
                                                    
                                                 
                                                
                                                
                                            
                                                 <br />
                                                {/* submit button */}

                                                {/* <button className="depositProceedButton"><i class="bi bi-send-check"></i> Proceed </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={goToPage}> Next {">>"} </button>
                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
                                        



                                      



                                    </div>

                                
                                </div>




                                   




                               




                        </div>
                     {/* end other content enters here */}




                    </div>                             
                     {/* end container holding main content */}











                     {/* start footer is starts here */}
                        {/* <DashFoot /> */}
                    {/* end footer is starts here */}                            

                </main>




            












   
               
                
               
          
   

            </section>





               










              













            </div>
    
        </>
    );



}

