// SavingsAccount






import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function SavingsAccount ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                    <li> <Link to='/personalbanking'> Sustainability </Link> </li>
                                    {/* <li> <Link to='/savingsaccount'> Environment </Link> </li> */}
                                    <li> <Link to='/currentaccount'> Governance </Link> </li>
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Environment </h2>

            
                                <p style={{ color: "#333", fontWeight: "bold"}}>
                                Sekai Resources Ltd prioritises responsible environmental stewardship



                                </p>
                                <p style={{ color: "#333", }}>
                                We strive to minimise our impact on the environment in the communities and natural habitats where we operate. Our management teams encourage and strengthen a shared, company-wide culture of environmentally responsible stewardship.




                                </p>
                                <p style={{ color: "#333", }}>
                                The Group complies with all applicable national environmental standards, environmental laws, and regulatory requirements at each of its mines and strives to adhere to international best practices across its asset base.





                                </p>
                                <p style={{ color: "#333", }}>
                                Sekai Resources Ltd’s sustainability objectives are incorporated into related operational improvement and development programmes. Through these, we seek opportunities to reduce Greenhouse Gas (GHG) emissions, water consumption and strive to ensure preservation of biodiversity.


                                </p>


                                <h2> Our commitments: </h2>
                                <p style={{ color: "#333", }}>
                                    1. Sustainable development in accordance with environmental commitments.


                                </p>
                                <p style={{ color: "#333", }}>
                                    2. Risk identification and management.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. Compliance with environmental regulations.


                                </p>
                                <p style={{ color: "#333", }}>
                                    4. Continuous improvement of the environmental management system.


                                </p>
                                <p style={{ color: "#333", }}>
                                    5. Integration of environmental aspects into all stages of operations.


                                </p>



                                
                                <p style={{ color: "#333", }}>
                                    6. A high level of environmental awareness among our employees, suppliers, and contractors.



                                </p>

                                
                                <p style={{ color: "#333", }}>
                                    7. Preserve biodiversity and avoid operations in highly sensitive areas.



                                </p>

                                
                                <p style={{ color: "#333", }}>
                                    8. Incident reporting and data transparency.



                                </p>

                                
                                <p style={{ color: "#333", }}>
                                    9. Reduce pollutant emissions.


                                </p>

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}