// PersonalBanking



import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function PersonalBanking ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                    {/* <li> <Link to='/personalbanking'> Personal Banking </Link> </li> */}
                                    <li> <Link to='/savingsaccount'> Environment </Link> </li>
                                    <li> <Link to='/currentaccount'> Governance </Link> </li>
                                   
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> More than gold </h2>

            
                                <p style={{ color: "#333", fontWeight: "bold",}}>
                                We believe that only a responsible business model built on sustainable and steady growth can deliver long-term success



                                </p>

                                <p style={{ color: "#333", }}>
                                Sekai Resources Ltd is fully committed to sustainability, and sustainable development principles are integrated into our operating philosophy. We have always been responsible environmental stewards and we strive to minimise and mitigate any negative impacts on our operations’ surroundings.



                                </p>

                                <p style={{ color: "#333", }}>
                                We are committed to ensuring a safe, fair, and rewarding working environment, with all employees united by a shared set of values. We also support and empower our host communities through impactful CSR initiatives and enduring partnerships to ensure that all stakeholders benefit from our presence.




                                </p>

                               
                                <p style={{ color: "#333", }}>
                                Sekai Resources Ltd aims to adhere to international best practices across our asset base. We are engaged in various projects to raise our operating standards and are working to achieve compliance with the highest internationally recognised codes and guidelines as well as the applicable national standards, laws and practices.





                                </p>

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}