
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';

import {Link } from "react-router-dom";

export default function Home () {
    return (
        <>
        
            <Head />
        

        {/* home section */}



{/* section 1 */}
        <section>

            <div className='showCaseContainer'>
                <div className='showCaseContainer_Sub'>

                    <div className='showCaseContainer_Sub_1'>

                        <div className='showCaseContainer_Sub_1_Container'>
                            <h3 className='showcaseHeadings_1'>  </h3>
                            <p className='showcasePara_1'>
                               
                            </p>

                            <div className='showCaseButtonContainer'>

                                <div className='showCaseButtonHideMobile'>

                                    <div className='showCaseButton_1'>
                                        <Link to="/login" className='showCaseButton_1_a' style={{ textTransform: "uppercase", }}>Login Your Online Account</Link>
                                    </div>

                                    <div className='showCaseButton_2'>
                                        {/* <a href="/login">Login</a> */}
                                    </div>


                                    
                                    <p className="clearx"></p>
                                </div>

                               

                                <p className='showCaseParaBelow' style={{color: "#fff"}}>
                                    Trusted By More Than 2M+ Users.
                                </p>
                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </section>




        
        

        {/* section 2 */}
        <section>
                <div className='aboutSection'>

                   

                    <div className='aboutSection_sub2'>
                        <div className='aboutSection_sub2_1'>
                            <div className='aboutSection_sub2_1_img'> </div>
                        </div>
                        <div className='aboutSection_sub2_2' >

                                <h2>About Sekai Resources Ltd </h2>

            
                                <p style={{ color: "#333",fontWeight: "bold", textTransform: "uppercase" }}>
                                Sekai Resources Ltd is a Canadian mining exploration and development company focused on revitalizing the Eskay Creek and Snip Projects, two past-producing mines located in Tahltan Territory in the Golden Triangle of northwest British Columbia, Canada.

                                </p>

                                <p style={{ color: "#333",fontWeight: "bold",textTransform: "uppercase"  }}>
                                Sekai Resources Ltd is an internationally diversified gold producer with over one million ounces of production per annum, operating a portfolio of eight mines located in the Russian Federation, Kazakhstan, Burkina Faso and South Africa

                                </p>

                                <p style={{ color: "#333", }}>
                                We take pride in everything we do. That’s why we aim to exceed your expectations and return outstanding value through our wide range of products and services.


                                </p>

                                <p style={{ color: "#333", }}>
                                A better world is important to us too. That’s why we work closely with not-for-profits and community organisations and support their initiatives through fundraising, donations and volunteering.


                                </p>

                                <h5> <b>  Our Mission: </b> </h5>
                                <p style={{ color: "#333", }}>
                                To become Canada's premier mineral development team; admired for our innovation, our creativity, and our commitment to deliver value and prosperity wherever we operate.

                                </p>



                                <h5> <b>Our Vision: </b> </h5>

                                <p style={{ color: "#333", }}>
                                To build an industry leading mining company that delivers value and prosperity to shareholders, employees, Indigenous Nation partners, and surrounding communities, and is committed to reconciliation with Indigenous peoples through responsible and sustainable mining development.

                                </p>
                                <h5><b>Values:</b></h5>
                                <p style={{ color: "#333", }}>
                                1. Health & Safety – everyone safe, every day. 
                                <br />
                                2. Environment – respect and protect the land for future generations.
                                <br />
                                3. Community – hand in hand, move forward together.
                                <br />
                                4. Integrity – be open, honest, and transparent.
                                <br />
                                5. Accountability – focus on commitments and deliverables.
                                <br />
                                6. Innovation – find creative solutions by leveraging new and emerging technologies.
                                {/* <br />
                                7. Execution – deliver consistently, efficiently and beyond what is expected. */}
                                
                                </p>

                                <Link to="/register" style={{ textDecoration: "none" }}>
                               
                                    <div className='companyCert'>
                                        <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                    </div>
                                </Link>

                                <br />
                                <br />

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>
        







        {/* section 4 */}
        <section>
            <div className='sectionFourContainer'>
                <div className='sectionFourContainerSub'>

                    <div className='sectionFourContainerSubCard1'></div>

                    <div className='sectionFourContainerSubCard2'>
                        <div className='sectionFourContainerSubCard2Details'>

                           


                            <div className='stepsContainer'>

                            <h3>
                            Corporate Profile
                            </h3>

                                <p style={{ color: "#333",fontWeight: "bold", textTransform: "uppercase" }}>
                                    Sekai Resources Ltd IS ONE OF THE WORLD'S LEADING CROP NUTRITION COMPANIES WITH A FOCUS ON POTASH AND PHOSPHATE, TWO OF THREE MOST VITAL NUTRIENTS.
                                </p>

                                <p style={{ color: "#333", }}>
                                Our mission is to help the world grow the food it needs. It's a compelling mission in many ways. For our employees, it's a noble purpose. For our customers, it says Sekai Resources Ltd is here to help them overcome a very daunting challenge. And for investors, it signals very significant long-term growth opportunities.

                                </p>

                                <p style={{ color: "#333", }}>
                                Sekai Resources Ltd is a gold miner of scale, with a diversified, high quality global asset portfolio and a proven track record of new mine development, having completed three new large-scale mines since 2013, each with a payback period of under 30 months. Guided by a strategy focused on finding, developing, and streamlining assets, we have a proven track record of creating value for stakeholders.


                                </p>



                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>

        </section>
        



            {/* faq section */}
            <section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p style={{ color: "#333", }}>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is opening an account is free?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div className="accordion-body">
                                        
                                        Yes, we don&#039;t take any fees for opening an account.

                                       </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        What is Sekai Resources Ltd?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333", textTransform: "lowercase,"}}>
                                        <div class="accordion-body">
                                        
                                        SEKAI RESOURCES LTD IS AN INTERNATIONALLY DIVERSIFIED GOLD PRODUCER WITH OVER ONE MILLION OUNCES OF PRODUCTION PER ANNUM, OPERATING A PORTFOLIO OF EIGHT MINES LOCATED IN THE RUSSIAN FEDERATION, KAZAKHSTAN, BURKINA FASO AND GUINEA


                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                            How to open an account?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Get the registration form by clicking on the Sign Up button on the top bar. Provide all information and click on the Sign Up button.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How do i reset my password?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If you have lost your pasword or you want to change it to a new one. Please click on the forgot password option and follow the steps to retrieve your password.
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How to take a loan?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#092980"}}>
                                        <div class="accordion-body">
                                        We have several loan plans. Choose the best plan suitable for you and just click on the Apply Now button and put the amount.

                                        </div>
                                        </div>
                                    </div>

                                    

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is Sekai Resources Ltd secure?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes. 100% Security. We measure up to all the reliable security operations.
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>




                {/* referral */}
                <section>
                        <div className='referralContainer'>

                            <div className='referralContainerSub' style={{ height: "auto" }}>
                                <h2>We provide our  services all over the world</h2>
                                <p>
                                Sekai Resources Ltd is a gold miner of scale, with a diversified, high quality global asset portfolio and a proven track record of new mine development, having completed three new large-scale mines since 2013, each with a payback period of under 30 months. Guided by a strategy focused on finding, developing, and streamlining assets, we have a proven track record of creating value for stakeholders.



                                </p>

                                <center>
                                <Link to="/register" className='referralContainerSub_Ppp' style={{ textTransform: "uppercase", }}>Open an account</Link>
                                </center>
                                
                            </div>

                        </div>


                </section>





           


            {/* testionial */}
            <section>
                    <div className='testimonailContainer'>
                        <div className='testimonailContainerSub'>
                        
                            <h2>How To Get Started</h2>
                            <p>
                            We make your life comfortable with our services.
                            </p>


                                <div className='testimonailCardContainer'>

                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>




                                            <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Register</h3>
                                                <p className='testimonailCardDetails1'>
                                                Sekai Resources Ltd users can click on the register button and proceed to provide all
                                                details for the registration and login their dashboard.
                                                
                                                </p>

                                            </div>
                                                

                                              



                                        </div>
                                    </div>






                                    
                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                               

                                                <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Deposit Funds</h3>
                                                <p className='testimonailCardDetails1'>
                                                Account-holders of Sekai Resources Ltd are able to deposit their money through our several payment systems. 
                                                


                                                </p>

                                            </div>


                                        </div>
                                    </div>


                                    <div className='testimonailCard'>
                                        <div className='testimonailCardDetails'>

                                                

                                                 <div className='testimonailCardDetails_Center'>

                                                <center>
                                                    <div className='testImage'>
                                                        {/* <img src="test/pro1.svg" alt="" width={50} height={50} /> */}
                                                        {/* <i class="bi bi-arrow-left-right"></i> */}
                                                    </div>
                                                </center>

                                                <h3>Withdraw Profits</h3>
                                                <p className='testimonailCardDetails1'>
                                                Sekai Resources Ltd users are able to withdraw money from their account, so you can trust Sekai Resources Ltd.


                                                </p>

                                            </div>


                                        </div>
                                    </div>



                                   
                                   
                                   
                                    <p className="clearx"></p>
                                </div>


                        </div>
                    </div>
            </section>












                <section>
                <div className='plansContainer'>
                    <div className='plansContainerSub'>
                            <center>
                                <h2 style={{ color: "#333", fontSize:18, }}>Our Plans for Investment</h2>
                                
                                <p style={{ color: "#092980", fontSize:28, fontWeight: "bold",}}>
                                We Have The Best  Plans

                                </p>
                            </center>

                                




                        <div className='plansContainerSubCard'>

                            <div className='plansContainerSubCardContent'>
                                
                                <center>
                               
                                <h4 className='planName' style={{ color: "#092980",fontWeight: "bold" }}>
                                Standard Plan
                                </h4>
                                    <h5 className='planName1' style={{ color:"#333", fontWeight: "bold" }}>5%</h5>
                                    <p style={{ color:"#333",fontWeight: "bold" }}>Daily</p>

                                   <p className='planName2' style={{ color:"#333", fontWeight: "bold"}}>
                                    Min. Amount: $100
                                    
                                    <br />

                                    Max. Amount: $49,999

                                    <br />

                                    Withdrawal: After 48hrs

                                    <br />

                                    Interest Rate: 5%

                                    </p> 

                                    <Link to="/login" style={{ textTransform: "uppercase", }}>Invest now</Link>

                                    <br />
                                    <br />
                                    {/* <h1 className='planName3'>
                                        [Calculate Profit]
                                    </h1> */}
                                    </center>

                            </div>

                        </div>
                        


                        
                        <div className='plansContainerSubCard'>

                            <div className='plansContainerSubCardContent'>
                                
                                <center>
                               
                                <h4 className='planName' style={{ color: "#092980",fontWeight: "bold" }}>
                                Gold Plan
                                </h4>
                                    <h5 className='planName1' style={{ color:"#333", fontWeight: "bold" }}>10%</h5>
                                    <p style={{ color:"#333",fontWeight: "bold" }}>Daily</p>

                                   <p className='planName2' style={{ color:"#333", fontWeight: "bold"}}>
                                    Min. Amount: $50,000
                                    
                                    <br />

                                    Max. Amount: $99,999

                                    <br />

                                    Withdrawal: After 96hrs

                                    <br />

                                    Interest Rate: 10%

                                    </p> 

                                    <Link to="/login" style={{ textTransform: "uppercase", }}>Invest now</Link>

                                    <br />
                                    <br />
                                    {/* <h1 className='planName3'>
                                        [Calculate Profit]
                                    </h1> */}
                                    </center>

                            </div>

                        </div>
                        


                        
                        <div className='plansContainerSubCard'>

                            <div className='plansContainerSubCardContent'>
                                
                                <center>
                               
                                <h4 className='planName' style={{ color: "#092980",fontWeight: "bold" }}>
                                Investors Plan
                                </h4>
                                    <h5 className='planName1' style={{ color:"#333", fontWeight: "bold" }}>15%</h5>
                                    <p style={{ color:"#333",fontWeight: "bold" }}>Daily</p>

                                   <p className='planName2' style={{ color:"#333", fontWeight: "bold"}}>
                                    Min. Amount: $100,000
                                    
                                    <br />

                                    Max. Amount: Unlimited

                                    <br />

                                    Withdrawal: After 7days

                                    <br />

                                    Interest Rate: 15%

                                    </p> 

                                    <Link to="/login" style={{ textTransform: "uppercase", }}>Invest now</Link>

                                    <br />
                                    <br />
                                    {/* <h1 className='planName3'>
                                        [Calculate Profit]
                                    </h1> */}
                                    </center>

                            </div>

                        </div>
                        


                        
                      
                        

















                        <p className="clearx"></p>
                    </div>



                </div>


            </section>





              {/* referral */}
              <section>
                        <div className='referralContainer' >

                            <div className='referralContainerSub' style={{ backgroundColor: "#092980", height: "auto"}}>
                                <h2 style={{ color: "white", fontWeight: "bold", }}>
                                We don't just help you reach financial milestones, we build lasting relationships.
                                </h2>
                               
                                <p style={{ color: "white", fontSize: 14,}}>
                                
                                Gold production is the Foundation of our work, but our values are more complex and multifaceted. We want our employees to be confident that their safety is the company's highest priority. It is important for us that the investment community trusts us. We strive to contribute to the development of the countries in which we work, paying special attention to environmental protection.




                                </p>

                                <center>
                                <Link to="/register" className='referralContainerSub_Ppp' style={{ textTransform: "uppercase", backgroundColor:"white", color: "#092980" }}>Open an account</Link>
                                </center>
                                
                            </div>

                        </div>


                </section>


                <div className='dfffcc'></div>
                        


                {/* footer */}

                <Foot  />








        
        
        
        
        
        
        
        </>
    );
}