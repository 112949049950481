import {Link } from "react-router-dom";

import {useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';


// toast notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import suc1 from "../images/suc12.gif";




// axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true;


export default function Login ()
{

const navigateRoutes = useNavigate();


const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [email, setEmail] = useState("");
const [username, setUsername] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [errMsg, setErrMsg] = useState("");
const [tokenCode, setTokenCode] = useState("");





useEffect(() => {

   
    window.location = "https://user.sekairesourceslimited.com/loginpage";
});











const submitRegisterForm = (e) => {
  


}













    return (
            <> 
            
                <Head />

                <div className='floatingCryptocoins'>
                    
                </div>

                    <section>

                        <div className='registerContainer'>



                        <div className='registeruserLoginContainer'>

<div className='registeruserLoginContainerSub'>

<div className='registeruserLoginContainerSubCard1'>
    {/* <img src="bg/login.svg" alt="Login Image" width={400} height={400} /> */}
</div>

<div className='registeruserLoginContainerSubCard2'>

        <div className='registeruserLoginContainerSubCard2Form'>
   
        {/* <button onClick={() => toast.success("humble web tech")}>
        Notify!
      </button> */}

      <ToastContainer />

                     <div className="row justify-content-center">
                        <div className="col-lg-12">
                     

                    </div>
                </div>

        </div>
</div>




                                    {/* start overlay modal */}
                                    <div className="modalOverlay" id="sucModal" style={{ display:"block" }}>
                                        <div className="modalOverlayContainer" style={{ height:"auto" }}>

                                            <center>
                                               
                                                
                                                <div className="modalPara">
                                                {/* <img src={suc1} width='250' style={{paddingTop: 0}} /> */}
                                                    

                                                    <div class="spinner-border text-light" role="status" style={{ backgroundColor:"red", }}>
                                                    <span class="sr-only" >  </span>
                                                    </div>

                                                </div>
                                               
                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}





<p className="clearx"></p>
</div>


</div>




                        </div>



                    </section>






                <Foot />
            
            
            
            
             </>

    );


}