
import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';



export default function Faq ()
{
    return (
            <> 
            
                <Head />


                <div className='floatingCryptocoins'>
                   
                </div>
                <br />
                <br />



        {/* faq section */}
        <section>
                <div className='faqContainer'>
                    <div className='faqContainerSub'>
                        <h2>Frequently Asked Questions</h2>
                        <p style={{ color: "#333", }}>
                            We have answered some of your Questions regarding our platform. If you still need help, Please contact us.
                        </p>



                        <div className='faqContainerSubForm'>

                            {/* start */}
            

                                <div class="accordion" id="accordionExample" >



                                    {/* start */}
                                    <div className="accordion-item" >
                                        <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is opening an account is free?
                                        </button>
                                        </h2>

                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div className="accordion-body">
                                        
                                        Yes, we don&#039;t take any fees for opening an account.

                                       </div>
                                        </div>

                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        What is Sekai Resources Ltd?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333", textTransform: "lowercase,"}}>
                                        <div class="accordion-body">
                                        
                                        SEKAI RESOURCES LTD IS AN INTERNATIONALLY DIVERSIFIED GOLD PRODUCER WITH OVER ONE MILLION OUNCES OF PRODUCTION PER ANNUM, OPERATING A PORTFOLIO OF EIGHT MINES LOCATED IN THE RUSSIAN FEDERATION, KAZAKHSTAN, BURKINA FASO AND GUINEA


                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo1" aria-expanded="false" aria-controls="collapseTwo1" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                            How to open an account?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo1" class="accordion-collapse collapse" aria-labelledby="headingTwo1" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Get the registration form by clicking on the Sing Up button on the top bar. Provide all information and click on the Sign Up button.
                                        </div>
                                        </div>
                                    </div>


                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo2" aria-expanded="false" aria-controls="collapseTwo2" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How do i reset my password?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo2" class="accordion-collapse collapse" aria-labelledby="headingTwo2" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        If you have lost your pasword or you want to change it to a new one. Please click on the forgot password option and follow the steps to retrieve your password.
                                        </div>
                                        </div>
                                    </div>

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo3" aria-expanded="false" aria-controls="collapseTwo3" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        How to take a loan?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo3" class="accordion-collapse collapse" aria-labelledby="headingTwo3" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#092980"}}>
                                        <div class="accordion-body">
                                        We have several loan plans. Choose the best plan suitable for you and just click on the Apply Now button and put the amount.

                                        </div>
                                        </div>
                                    </div>

                                    

                                    {/* start */}
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo5" aria-expanded="false" aria-controls="collapseTwo5" style={{backgroundColor: "#092980", color: "#fff", fontWeight: "bold",}}>
                                        Is Sekai Resources Ltd secure?
                                        </button>
                                        </h2>
                                        <div id="collapseTwo5" class="accordion-collapse collapse" aria-labelledby="headingTwo5" data-bs-parent="#accordionExample" style={{backgroundColor: "#fff", color: "#333"}}>
                                        <div class="accordion-body">
                                        Yes. 100% Security. We measure up to all the reliable security operations.
                                        </div>
                                        </div>
                                    </div>


                                    
                                </div>

                            {/* end */}
                        </div>


                    </div>


                </div>   
            </section>














            <div className='dfffcc'></div>
            

                
                <Foot />
            
            
            
            
             </>

    );


}