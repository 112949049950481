// PrivateBanking





import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function PrivateBanking ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   {/* <li> <Link to='/privatebanking'> Private Banking </Link> </li> */}
                                   <li> <Link to='/creditcardservices'> Credit Card Services </Link> </li>
                                    <li> <Link to='/discretionaryport'> Discretionary Portfolios </Link> </li>
                                    <li> <Link to='/investorvisaport'> Investor Visa Portfolios </Link> </li>
                                    <li> <Link to='/executiononlyport'> Execution Only Portfolios </Link> </li>
                                    
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Private Banking </h2>

            
                                <p style={{ color: "#333", }}>
                                We appreciate that you have limited time to spend thinking about your day-to-day banking needs or addressing your long-term investment objectives…



                                </p>
                                <p style={{ color: "#333", }}>
                                Our Private Bank offers a unique investment approach and a commitment to understanding you and your needs to offer a totally tailor-made investment solution.


                                </p>


                                <p style={{ color: "#333", }}>
                                Our Global Bank is dedicated to helping you reach your wealth management and financial goals. We provide you with an individually tailored investment solution which is complemented by a personal banking service.



                                </p>
                                <p style={{ color: "#333", }}>
                                We are committed to providing you with an exclusive and bespoke excellent level service by working with you to build a long-term relationship based on understanding, knowledge, trust and accountability.



                                </p>
                                <p style={{ color: "#333", }}>
                                We assure you of our complete discretion when dealing with your financial affairs. Confidentiality and discretion are at the very core of our business. Our operations are authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and Prudential Regulation Authority, Data Protection Regulations and the Banking Act. This ensures that your personal information is held securely and confidentially at all times.



                                </p>


                                {/* <h2> Why choose our Business Account? </h2>
                                <p style={{ color: "#333", }}>
                                    1. You will have your own dedicated Relationship Manager who will guide you and help you with all your needs.

                                </p>
                                <p style={{ color: "#333", }}>
                                    2. We offer same-day transfers between your USA Account to accounts held with Sekai Resources Ltd subsidiaries.


                                </p>
                                <p style={{ color: "#333", }}>
                                    3. You can choose to open a US Dollar, GBP or Euro current account to facilitate your international transactions.

                                </p>
                                <p style={{ color: "#333", }}>
                                    4. You will be offered a cheque book and paying-in book for your account.

                                </p>
                                <p style={{ color: "#333", }}>
                                    5. You will be able to control your finances any day, anytime though our quick and easy Online banking service.

                                </p>
                                <p style={{ color: "#333", }}>
                                    6. You will be able to set up Standing Orders and Direct Debits with our simple and straightforward internet banking.

                                </p>
                                <p style={{ color: "#333", }}>
                                    7. We will send your monthly statements to your chosen correspondence address.

                                </p> */}

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}