// DashCard


// DashReferral


import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";



// import dash images
import cardimg from "../DashImg/cardimg.svg";
import masterimg from "../DashImg/masterimg.png";
import masterchip from "../DashImg/masterchip.png";




import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


export default function DashCard(){
    const navigateRoutes = useNavigate();
    const [errMsg, setErrMsg] = useState("");

    const [fullName, setFullName] = useState("");


    const [withdrawalLimit, setwithdrawalLimit] = useState("");
    const [howSoon, sethowSoon] = useState("");
    const [withdrawalPin, setwithdrawalPin] = useState("");

    const [msgLabel, setMsgLabel] = useState("");
    const [msgPara, setMsgPara] = useState("");



    useEffect(() => {

        const acctnameaa = localStorage.getItem('acctnameaa');
        
       if(acctnameaa){

        setFullName(acctnameaa);
       
       
        
       }else{
        
        navigateRoutes('/login', { replace: true });

       }
       

    }, []);





    const hideModalContainerMsg = () => {
        $("#showModalContainerMsg").hide();


        navigateRoutes('/dashboard');

        // $("#depositProceedButton").show();
        // $("#processingButton").hide();
    }


    const confirmRequest = (e) => {

        e.preventDefault();

        $("#depositProceedButton").hide();
        $("#processingButton").show();

    
        if(withdrawalLimit == "" || withdrawalLimit == "Select"){
            toast.error("Select a withdrawal Limit");
            setErrMsg("Select a withdrawal Limit");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;


        }else if(howSoon == "" || howSoon == "Select"){
            toast.error("Select how soon do you need the card");
            setErrMsg("Select how soon do you need the card");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else if(withdrawalPin == ""){
            toast.error("Enter  a 4 didgit card pin");
            setErrMsg("Enter  a 4 didgit card pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    

        }else if(withdrawalPin.length < 4 || withdrawalPin.length > 4 ){
            toast.error("Enter  a 4 didgit card pin");
            setErrMsg("Enter  a 4 didgit card pin");
            $("#depositProceedButton").show();
            $("#processingButton").hide();
            return false;
    


        }else{


            setErrMsg("");

            // starts here 
            const checkData1 = localStorage.getItem('tokenID');
            const checkData2 = localStorage.getItem('currentUserName');
            
            if(checkData1 && checkData2){
                let urls = "http://127.0.0.1:8000/api/activatedebitcardpost";
            
                async function proceedToSendDataToServer (){
                    try{
                        const registerPostFeedBack = await axios.post(urls,{
            
                            tokenCode: checkData1,
                            userName: checkData2,

                            withdrawalLimit: withdrawalLimit,
                            howSoon: howSoon,
                            withdrawalPin: withdrawalPin,
            
            
            
                        }).then((res) => {
            
                            let feedbackMsg = res.data.msg;
                            let feedbackStatusCode = res.data.status;
            
            
                            if(feedbackStatusCode == 200){
                                toast.success(feedbackMsg);
                                
                                setMsgLabel("Debit Card Request");
                                setMsgPara("Debit card request was successful,a mail will be sent to you once your debit card is activated.");
                                setTimeout(() => {
                                    $("#showModalContainerMsg").show();
                                  }, 1000);
                              
                            }

            
                            if(feedbackStatusCode == 402){
                                setErrMsg(feedbackMsg);
                                $("#errMsg").show();
                                $("#depositProceedButton").show();
                                $("#processingButton").hide();
                            }
            
                            if(feedbackStatusCode == 401){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            if(feedbackStatusCode == 501){
                                navigateRoutes('/login', { replace: true });
                            }
            
                            
            
                        });
            
                   }catch (err){
            
                    toast.error("Error connecting to the servers.");
                    setErrMsg("Error connecting to the servers.");
                    $("#depositProceedButton").show();
                    $("#processingButton").hide();
             
                    //  console.log(err);
            
                 }
            
            
                }
                proceedToSendDataToServer();
            
            
            }else{
            
            navigateRoutes('/login', { replace: true });
            }
            
            







        }


    
    
    
    
    
    
    }












    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">





                                <ToastContainer />  



                                    
                                        {/* box 1 */}
                                    <div className="mainContent_1_Container_box_1">
                                            <div className="mainContent_1_Container_box_1_Content" >

                                                
                                                

                                                {/* <p className="">card image here</p> */}

                                                <div class="flip-box">
                                                    <div class="flip-box-inner">
                                                        
                                                       
                                                        <div class="flip-box-front frontViewCard">
                                                       
                                                            <div className="frontViewContainer">
                                                                {/* start first stage */}
                                                                <div className="frontViewContainer_1">
                                                                    <div className="frontViewContainer_11">
                                                                        <img src={masterimg} style={{width: "35px"}} />
                                                                    </div>
                                                                    <div className="frontViewContainer_12">
                                                                        <p>Master Card</p>
                                                                    </div>
                                                                    <div className="frontViewContainer_13">
                                                                     <img src={masterchip} style={{width: "35px"}} />
                                                                    </div>
                                                                </div>
                                                                 {/* end first stage */}


                                                                  {/* start second stage */}
                                                                  <div className="frontViewSeconStage">
                                                                        <p className="frontViewSeconStage_para_1">Card Number</p>
                                                                        <h5 className="frontViewSeconStage_para_2">8050 5040 xxxx xxxx</h5>
                                                                  </div>
                                                                  {/* end second stage */}

                                                                  {/* start third stage */}
                                                                  <div className="frontViewThirdStage">
                                                                        <div className="frontViewThirdStage_1">
                                                                            <p>{fullName}</p>
                                                                        </div>
                                                                        <div className="frontViewThirdStage_2">
                                                                            <p>Valid Thru <br /> 05/30</p>
                                                                        </div>
                                                                  </div>

                                                            </div>


                                                        </div>

                                                         
                                                        <div class="flip-box-back backViewcARD">
                                                            <div className="cardBackView">
                                                                <h3 style={{ 
                                                                    color: 'white',
                                                                 }}>Sekai Resources Ltd Card</h3>

                                                                <div className="cardBackView_2">
                                                                    <div className="cardBackView_2_1"></div>
                                                                    <div className="cardBackView_2_2">
                                                                        <p>5xx</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                               
                                            </div>

                                        </div>



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer" >
                                            <div className="depositMainContainer_Sub" style={{ height: "auto", }}>

                                            <form>
                                               

                                               
                                                    <p style={{ color: "red" }}>
                                                        {errMsg}
                                                    </p>

                                                
                                                <label htmlFor="fullname" className='depositFormLabel'>Set Withdrawal Limit. </label> <br />
                                                
                                                    <div className="depositAmountContainer">
                                                        <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => setwithdrawalLimit(e.target.value)}>
                                                            <option value="Select"> -- Select -- </option>
                                                            <option value="$10,000">$10,000 </option>
                                                            <option value="$20,000">$20,000 </option>
                                                            <option value="$50,000">$50,000 </option>
                                                            <option value="$100,000">$100,000 </option>
                                                            <option value="Unlimited">Unlimited </option>
                                                        </select>
                                                    </div>


                                                <br /> 

                                                <label htmlFor="fullname" className='depositFormLabel'>How soon do you need the card? </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    <select className='DepositformInputSelect' style={{height:40}} onChange={(e) => sethowSoon(e.target.value)}>
                                                        <option value="Select"> -- Select -- </option>
                                                        <option value="Immediately">Immediately </option>
                                                        <option value="Within two weeks">Within two weeks </option>
                                                        <option value="Within a month">Within a month </option>
                                                        <option value="Not sure yet">Not sure yet </option>
                                                        
                                                    </select>
                                                </div>

                                                <br />

                                                <label htmlFor="fullname" className='depositFormLabel'>Enter 4 digit card pin. </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    {/* <div className="depositAmountContainer_1">
                                                        <span>$</span>
                                                    </div> */}
                                                    <div className="depositAmountContainer_2">
                                                    <input type="number" placeholder="****"   className='DepositformInputAmount' onChange={(e) => setwithdrawalPin(e.target.value)} />
                                                    </div>
                                                </div>

                                                <br />
                                                <label htmlFor="fullname" className='depositFormLabel'>Re-enter  4 digit card pin. </label> <br />
                                                
                                                <div className="depositAmountContainer">
                                                    {/* <div className="depositAmountContainer_1">
                                                        <span>$</span>
                                                    </div> */}
                                                    <div className="depositAmountContainer_2">
                                                    <input type="number" placeholder="****"   className='DepositformInputAmount' onChange={(e) => setwithdrawalPin(e.target.value)} />
                                                    </div>
                                                </div>



                                            <br /> 
                                                


                                                {/* submit button */}

                                                {/* <button className="depositProceedButton">Apply Now </button> */}
                                                <button className="depositProceedButton" id="depositProceedButton" onClick={confirmRequest}>APPLY NOW <i class="bi bi-cash"></i> </button>

                                                <button id="processingButton" className="cmn-btn w-100 buttForm"  disabled> 
                                                    <div class="spinner-border" role="status" style={{ width: 18, height:18 }}>
                                                        <span class="visually-hidden" >Loading...</span>
                                                    </div>
                                                    PROCESSING...
                                                </button>
                                            </form>
                                                
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={cardimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}




  {/* start overlay modal */}
  <div className="modalOverlay" id="showModalContainerMsg">
                                                        <div className="modalOverlayContainer" style={{  height: "auto", padding:40, }}>

                                            <center>
                                                {/* succImg */}
                                                <img src={suc1} width='60' style={{paddingTop: 30}} />
                                                {/* <img src={errimg} width='70' style={{paddingTop: 30}} /> */}

                                                <h3> {msgLabel} </h3>

                                                {/* <h3 style={{color: "red"}}>Error </h3> */}
                                                
                                                <div className="modalPara">
                                                    <p>
                                                        {msgPara}
                                                    </p>

                                                  

                                                </div>
                                               


                                                <div className="modalButtons"> 

                                                    {/* <div className="confirmModalButton">
                                                        <p>
                                                            Confirm
                                                        </p>
                                                    </div> */}

                                                    {/* cancelModalButton */}
                                                    <div className="confirmModalButton" onClick={hideModalContainerMsg}>
                                                        <p>
                                                            OK
                                                        </p>
                                                    </div>

                                                </div>

                                            </center>
                                        

                                        </div>
                                    </div>
                                    {/* end overlay modal */}










                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}