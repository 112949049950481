// BusinessBanking










import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function BusinessBanking ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                   {/* <li> <Link to='/businessbanking'> Business Banking </Link> </li> */}
                                   <li> <Link to='/businessaccount'> Business Account </Link> </li>
                                    <li> <Link to='/tradefinance'> Trade Finance </Link> </li>
                                   
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Business Banking  </h2>

            
                                <p style={{ color: "#333", }}>
                                At the Sekai Resources Ltd, your Business current account comes with the backing and expertise of your Relationship Manager and their support team.


                                </p>

                                <p style={{ color: "#333", }}>
                                Our dedicated Relationship Managers are highly experienced with many having spent time working in the international marketplaces. We are committed to providing you with an excellent level of service tailored to your needs by working with you to build a long-term relationship.

                                </p>
                                <p style={{ color: "#333", }}>
                                You’ll get a personalised service, local knowledge and access to specialist products and services that can help you control your cash flow and develop your business for the future.


                                </p>


                                
                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}