// CurrentAccount








import Head from '../HomePages/Head';
import Foot from '../HomePages/Foot';
import {Link } from "react-router-dom";


export default function CurrentAccount ()
{
    return (
            <> 
            
                <Head />



                <section>
                <div className='aboutSectionP' >

                    <div className='floatingCryptocoins'>
                    
                    </div>

                    <div className='aboutSection_sub2P'>

                        <div className='aboutSection_sub2_1P' >
                            {/* <div className='aboutSection_sub2_1_img1'> </div> */}

                            {/* <h3>Other Links</h3> */}
                            <div className='aboutSection_sub2_1_OtherLinkP' style={{ height: "auto", padding: 20,}}>
                                <h3>Related Links</h3>
                                <ul>
                                <li> <Link to='/personalbanking'> Sustainability </Link> </li>
                                    <li> <Link to='/savingsaccount'> Environment </Link> </li>
                                    {/* <li> <Link to='/currentaccount'> Governance </Link> </li> */}
                                </ul>
                            </div>
                        </div>


                        <div className='aboutSection_sub2_2P' >

                                <h2> Governance </h2>

            
                                <p style={{ color: "#333", }}>
                                Sekai Resources  implements its robust environmental, social and Sekai Resources  and sustainable development framework, which is overseen by the Board’s Safety and Sustainable Development Committee, together with the Director of ESG.



                                </p>

                                <p style={{ color: "#333", }}>
                                The safety and sustainable development committee ("SSD Committee") operates pursuant to a mandate approved by the Board. Members of the SSD Committee are appointed by the Board.



                                </p>
                                <p style={{ color: "#333", }}>
                                The SSD Committee is responsible for monitoring and evaluating reports on the effectiveness of safety and sustainable development policies, management standards, strategy, performance and Sekai Resources across the Group, and reports to the Board on key issues.




                                </p>


                                

                               




                                

                                

                               


                                <Link to="/register" style={{ textDecoration: "none" }}>
                                <div className='companyCert'>
                                    <p className='companyCert_p' style={{ textTransform: "uppercase", }}>Open an account</p>
                                </div>
                                </Link>


                                

                        </div>



                        <p className="clearx"></p>
                    </div>







                </div>



        </section>






                   












                <Foot />
            
            
            
            
             </>

    );


}