// ViewReciept




import $ from "jquery";
import {Link } from "react-router-dom";


// import pages
import DashNav from "./DashNav";
import DashHead from "./DashHead";
import DashFoot from "./DashFoot";


import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import suc1 from "../DashImg/suc1.gif";
import errimg from "../DashImg/errimg.png";
import {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import logo from "../images/logo.png";

// import dash images
import withimg from "../DashImg/withimg.svg";

export default function ViewReciept(){

    const navigateRoutes = useNavigate();

    const [receiverAcctName, setReceiverAcctName] = useState("");
    const [receiverAcctNumber, setReceiverAcctNumber] = useState("");
    const [receiverBankName, setReceiverBankName] = useState("");
    const [sendingAmount, setSendingAmount] = useState("");
    const [dateOfTransaction, setdateOfTransaction] = useState("");
    const [senderAcctName, setsenderAcctName] = useState("");
    const [senderAcctNumb, setsenderAcctNumb] = useState("");
    const [transactID, setTransactID] = useState("");



    useEffect(() => {


        const receiverAcctNamea = Cookies.get('receiverAcctName');
        const receiverAcctNumbera = Cookies.get('receiverAcctNumber');
        const receiverBankNamea = Cookies.get('receiverBankName');
        const sendingAmounta = Cookies.get('sendingAmount');
        const transferDatea = Cookies.get('transferDate');
        const currentTransactionID = Cookies.get('currenttransactionID');

        const senderAcctName = localStorage.getItem('acctnameaa');
        const senderAcctNumb = localStorage.getItem('acctnum');
        


        
        if(receiverAcctNamea && receiverAcctNumbera && receiverBankNamea && sendingAmounta  && senderAcctName && senderAcctNumb){

           

            // set
            setReceiverAcctName(receiverAcctNamea);
            setReceiverAcctNumber(receiverAcctNumbera);
            setReceiverBankName(receiverBankNamea);
            setSendingAmount(sendingAmounta);
            setdateOfTransaction(transferDatea);
            setsenderAcctName(senderAcctName);
            setsenderAcctNumb(senderAcctNumb);
            setTransactID(currentTransactionID);


            
        }else{
         
            // console.log("nah1");
         navigateRoutes('/thirdsecuritycode',);
 
        }


        
 
 
     }, []);
 



     const clearTransferCookieLogs = (e) => {
        e.preventDefault();


        Cookies.remove("receiverAcctName");
        Cookies.remove("receiverAcctNumber");
        Cookies.remove("receiverBankName");
        Cookies.remove("receiverRoutingNumber");
        Cookies.remove("receiverSwiftcode");
        Cookies.remove("sendingAmount");
        Cookies.remove("sendingPurpose");
        Cookies.remove("transferType");
        Cookies.remove("currenttransactionID");
        Cookies.remove("transferDate");


        navigateRoutes('/dashboard');
       
       
    }








    return(
        <>
        <div className="mainDashIndexContainer">

            {/* import dashnav here */}

                <DashNav />


         {/* main section */}
         <section className="mainContainerSection">


            {/* import dash nav here */}
                <DashHead />






            

        {/* main */}

            <main className="mainDashContent">



                {/* start container holding main content */}
               <div className="mainDashContent_Sub">

                    
                    {/* start other content enters here */}
                    <div className="mainDashContent_Sub_Content">

                                    {/* dash view content */}
                            <div className="mainContent_1">
                                <div className="mainContent_1_Container">
                                    
                                       



                                        {/* box 2 */}

                                                                                
                                        <div className="depositMainContainer">
                                            <div className="depositMainContainer_Sub" style={{ height: "auto" }}>
                                                
                                                {/* progress bar1 */}
                                            {/* <div className="progressContainerForCode">
                                                <div className="progressBar_ConfirmTransfer"></div>
                                            </div> */}

                                              
                                                    {/* <h3 className="codeLabel">Confirm Transfer</h3> */}

                                                    
                                                    <div className="confirmMainContainer">
                                                        <br />
                                                        


                                                        {/* start */}

                                                        <div className="viewReceiptconfirmMainContainer_Sub">
                                                            <div className="viewReceiptconfirmMainContainer_Sub_1">
                                                                {/* <p>Bank Logo </p> */}
                                                                <Link to='/'>
                                                                    {/* <h2 style={{ color: "#092980", fontWeight: "bold", fontSize:20, }}> Sekai Resources Ltd </h2> */}
                                                                    <img src={logo} width={100} style={{ 
                                                                        position:"relative",
                                                                        top: -30,
                                                                    }}  />
                                                                </Link>
                                                            </div>
                                                            <div className="viewReceiptconfirmMainContainer_Sub_2">
                                                                <p style={{ fontWeight: "bold", }}>Transaction Receipt</p>
                                                            </div>
                                                        </div>
                                                        <br />

                                                        <center>
                                                            <div className="viewReceiptContainer_Sub">

                                                                <h3>${sendingAmount}</h3>
                                                                <p>Successful</p>
                                                                <small className="viewReceiptContainer_Sub_Small"> {dateOfTransaction} </small>

                                                                <br />
                                                                <br />
                                                                <div className="viewReceiptContainer_Sub_Divider"></div>
                                                            </div>
                                                           

                                                            <br />
                                                           
                                                        </center>
                                                        








                                                        <div className="viewReceiptconfirmMainContainer_Sub">
                                                            <div className="viewReceiptconfirmMainContainer_Sub_1">
                                                                <p>Transaction Type: </p>
                                                            </div>
                                                            <div className="viewReceiptconfirmMainContainer_Sub_2">
                                                                <p>Debit</p>
                                                            </div>
                                                        </div>

                                                        <div className="viewReceiptconfirmMainContainer_Sub">
                                                            <div className="viewReceiptconfirmMainContainer_Sub_1">
                                                                <p>Beneficiary Details: </p>
                                                            </div>
                                                            <div className="viewReceiptconfirmMainContainer_Sub_2">
                                                                <p>{receiverAcctName}

                                                                    <br />
                                                                    {receiverAcctNumber} | {receiverBankName}

                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="viewReceiptconfirmMainContainer_Sub">
                                                            <div className="viewReceiptconfirmMainContainer_Sub_1">
                                                                <p>Sender Details: </p>
                                                            </div>
                                                            <div className="viewReceiptconfirmMainContainer_Sub_2">
                                                            <p> {senderAcctName}

                                                                <br />
                                                                {senderAcctNumb} | Sekai Resources Ltd

                                                            </p>
                                                            </div>
                                                        </div>

                                                        <div className="viewReceiptconfirmMainContainer_Sub">
                                                            <div className="viewReceiptconfirmMainContainer_Sub_1">
                                                                <p>Transaction ID: </p>
                                                            </div>
                                                            <div className="viewReceiptconfirmMainContainer_Sub_2">
                                                                <p>{transactID}</p>
                                                            </div>
                                                        </div>

                                                        <div className="confirmProceedButtonContainer">
                                                                    <button className="confirmProceedButton"> DOWNLOAD RECIEPT  </button> 

                                                                
                                                                    <button className="confirmProceedButton" onClick={clearTransferCookieLogs}> DONE </button>
                                                                </div>

                                                        {/* end */}

                                                       



                                                    </div>
                                                 







                                                <br /> 
                                                
                                            

                                                {/* submit button */}

                                                
                                               
                                                    
                                            </div>

                                              
                                        </div>
























                                    
                                   


                                    




                                </div>
                            </div>






                            {/* image container */}
                            <div className="mainContent_2">
                                <div className="mainContent_2_Container">
                                 <img src={withimg} style={{width: "100%", height: "50vh" }}  />
                                </div>
                            </div>





                    </div>
                 {/* end other content enters here */}




                </div>                             
                 {/* end container holding main content */}











                 {/* start footer is starts here */}
                 {/* <DashFoot /> */}
                {/* end footer is starts here */}                            

            </main>




        













           
            
           
      


        </section>





           










          













        </div>

    </>

    );




}